const BASE_URL = "https://api.publicapis.org";
const PAYMENT_BASE_URL = 'https://winterfever-api.trichter.nl/api/v1';
// const PAYMENT_BASE_URL = 'http://10.10.50.152:3000/api/v1';

export default {
  entriesEndpoint: `${BASE_URL}/entries`,
  paymentMethodsEndpoint: `${PAYMENT_BASE_URL}/payment/methods/all`,
  startTopUpTranscationEndpoint: `${PAYMENT_BASE_URL}/payment/topupTransaction/start`,
  getTopUpTransactionStatus: `${PAYMENT_BASE_URL}/payment/transaction/status/`,
  paymentRefundTransactionEndpoint: `${PAYMENT_BASE_URL}/payment/refund/request`,
  getRFIDCreditEndPoint: `${PAYMENT_BASE_URL}/payment/rfid/balance/`,
  getCompanyDetailsPublicEndPoint: `${PAYMENT_BASE_URL}/company/current/details`,

  tokenType: "Bearer",

  storageTokenKeyName: "accessToken",
  storageRefreshTokenKeyName: "refreshToken",
};